<template>
  <div class="home-ele">
    <div class="main_box">
      <div class="tabs_box">
        <div class="tabs">
          <div
            :class="['item', currentActive == 0 ? 'active' : '']"
            @click="chageTba(0)"
          >
            {{ langvalue.line.one }}
          </div>
          <div
            :class="['item', currentActive == 1 ? 'active' : '']"
            @click="chageTba(1)"
          >
            {{ langvalue.line.two }}
          </div>
        </div>
        <div class="right">
          <div class="_tabs_box">
            <div class="_tabs">
              <div
                :class="['_item', day == 0 ? 'active' : '']"
                @click="chageDate(0)"
              >
                {{ langvalue.line.three }}
              </div>
              <div
                :class="['_item', day == 1 ? 'active' : '']"
                @click="chageDate(1)"
              >
                {{ langvalue.line.four }}
              </div>
            </div>
          </div>
          <div class="_title">
            BTC {{ day == 0 ? '7' : '30' }}{{ langvalue.line.five
            }}{{ currentActive == 0 ? langvalue.line.one : langvalue.line.two }}
          </div>
        </div>
      </div>

      <div class="_line_box">
        <div
          class="chart"
          id="line"
          :style="{ width: '1340px', height: '600px' }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { returnLanguage } from '../../../assets/js/language'
import { getLine } from '@/api/new'
import * as echarts from 'echarts'
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent
} from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { format } from '@/utils/utils'

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])
export default {
  name: 'AppHeaderNav',
  props: ['isto'],
  setup(props) {
    let isJump = ref(props.isto)
    const store = useStore()
    const router = useRouter()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)

    const langvalue = ref(returnLanguage(langtype))

    let currentActive = ref(0)
    let day = ref(0)
    const getData = async () => {
      let params = {
        type: currentActive.value + 1,
        day: day.value == 1 ? '30' : '7'
      }
      // console.log(params, '请求');
      let { data } = await getLine({ ...params })
      // console.log(currentActive.value);
      if (!data){
        data = []
      } else {
        createChart(data, currentActive.value)
      }
      // console.log('data', data)
      // return
      
    }
    const createChart = (data, type) => {
      let chartDom = document.getElementById('line')
      let myChart = echarts.init(chartDom)
      let dateArr = data.map(function(item, index) {
        return format(item.time*1000, 'MM-dd')
      })
      let dataArr = []
      let priceArr = []
      if (type == 0) {
        dataArr = data.map(function(item, index) {
          return item.data
        })
      } else {
        priceArr = data.map(function(item, index) {
          return item.data
        })
      }
      let option = {
        tooltip: {
          trigger: 'axis',
          formatter: function(arg) {
            let targ = arg[0]
            let value = type == 0 ? targ.value + ' EH/S' : '$ ' + targ.value
            return targ.name + '<br/>' + targ.marker + targ.seriesName + ' ' + value
          },
          position: function(pt) {
            return [pt[0], '10%']
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dateArr
        },
        yAxis: {
          type: 'value',
          name: type == 0 ? 'EH/S' : 'USD',
          // show: true,
          axisLabel: {
            show: true,
            color: '#666', //y轴文字颜色
            // formatter: value => {
            //   //处理y轴内容过长
            //   if (value > 1000000) {
            //     value = value / 1000000 + '百万'
            //   }
            //   return ''
            // }
          },
          min: (value) => {
            return value.min
            
          },

        },
        // dataZoom: [
        //   {
        //     type: 'inside'
        //   },
        //   {
        //     startValue: 0
        //   }
        // ],
        series: [
          {
            name: type == 0 ? '全网算力' : '价格走势',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: type == 0 ? '#6267f0' : '#52c2ab'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: type == 0 ? '#babdfe' : '#52e7ca'
                },
                {
                  offset: 1,
                  color: type == 0 ? '#e8f4fd' : '#f0f8fb'
                }
              ])
            },
            data: type == 0 ? dataArr : priceArr
          }
        ]
      }
      option && myChart.setOption(option)
    }

    const chageTba = index => {
      currentActive.value = index
      getData()
    }
    const chageDate = index => {
      day.value = index
      getData()
    }
    onMounted(() => {
      getData()
    })
    watch(
      () => store.state.home.type,
      (newVal, oldvalu) => {
        store.commit('home/setType', newVal)
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
        getData()
      },
      { immediate: true }
    )
    return { langvalue, type, currentActive, chageTba, day, chageDate }
  }
}
</script>
<style scoped lang="less">
.home-ele {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #fafbff;
  .main_box {
    width: 1340px;
    margin: 0 auto;
    padding-top: 30px;
    .tabs_box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        padding: 6px;
        box-sizing: border-box;
        border-radius: 30px;
        border: 1px solid #d5dce3;
        background: #f8f8fa;
        .item {
          // width: 191px;
          height: 39px;
          line-height: 39px;
          text-wrap: wrap;
          text-align: center;
          align-items: center;
          flex-shrink: 0;
          padding: 0 20px;
          &.active {
            // width: 191px;
            color: #2889fc;
            border-radius: 30px;
            border: 2px solid #2889fc;
            background: #d3e0ff;
          }
        }
      }

      .right {
        position: absolute;
        right: 0;
        top: 10px;
        ._tabs_box {
          ._tabs {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            border: 1px solid #4d56db;
            width: 140px;
            padding: 4px;
            ._item {
              width: 63px;
              height: 29px;
              line-height: 29px;
              border-radius: 20px;

              color: #4d56db;

              font-size: 14px;
              &.active {
                color: #fff;
                background: #4d56db;
              }
            }
          }
        }
        ._title {
          margin-top: 30px;
        }
      }
    }
    ._line_box {
      height: 600px;
      margin-top: 40px;
    }
  }
}
</style>
