<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import { MiningMachine, getSl } from '@/api/new'
// 算力播报
import HomeSlbb from './components/home-slbb'

import AboutBanner from './components/about-banner'
import HomeLine from './components/home-line'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))

let configShow = ref(true)
// console.log(langvalue.value.top.one, '测试')
const toggleConfig = () => {
  configShow.value = !configShow.value
}
const checkedtype = ref(langvalue.value.Bus_introduction.four)
const type = ref(langtype)
let goods_list = ref([])

let yxsl = ref(1) // 有效算力
let sy_ll = ref(0) // 收益
let bj = ref(0) // 比特币价
let nd = ref(0) // 难度
let fl = ref(0) // 费率
let ll_sy = ref(0) // 理论收益
let electricity = ref(0.06) // 用电

let ll
let sy


const getConfi = async () => {
  let {data} = await getSl()
  ll = data.list.filter(e => e.id == 16)[0]
  sy = data.list.filter(e => e.id == 17)[0]
  bj.value = data.list.filter(e => e.id == 4)[0].value.split(' ')[1]
  nd.value = data.list.filter(e => e.id == 3)[0].value
  ll_sy.value = ll.value
  sy_ll.value = sy.value
  geList()
}
const geList = async () => {
  let {data: {list}} = await MiningMachine({ electricity: electricity.value})
  goods_list.value = list
  // console.log(list, '测试测试');

}
// 计算
const comput = () => {
  if(yxsl.value == 1){
    sy_ll.value = sy.value
  }else{
    sy_ll.value = sy.value * yxsl.value
  }
  
  ll_sy.value = ll.value * yxsl.value
  geList()
}

const duChange = (e) => {
  geList()
}
getConfi()
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).Bus_introduction.four
    type.value = newVal
    getConfi()
  },
  { immediate: true }
)
</script>

<template>
  <div class="a_detail">
    <AboutBanner
      :checkedtype="checkedtype"
      title="Computational power broadcasting"
    />
    <HomeSlbb :isto="false" />
    <div class="center">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.Bus_introduction.five }}</span>
        <span class="line"></span>
      </div>
      <div class="c_cont">
        <p class="tips">
          <span class="t_titlt">{{ langvalue.Bus_introduction.six + ':' }}</span
          ><span>{{ langvalue.Bus_introduction.seven }}</span>
        </p>
        <div class="cont_box">
          <div class="box_top">
            <div class="_item">
              <div class="_item_sec">
                <p class="lebal">{{ langvalue.Bus_introduction.eight }}</p>
                <div class="item_cont">
                  <div class="selec_box">
                    <select name="">
                      <option value="0">BTC</option>
                    </select>
                  </div>
                  <div class="input_box">
                    <input  class="" v-model="yxsl" />
                    <div class="extend">Thash/s</div>
                  </div>
                </div>
              </div>
              <div class="img_box" @click="comput">
                <img src="@/assets/images/js.png" alt="" />
              </div>
              <div class="_item_sec">
                <p class="lebal">{{ langvalue.Bus_introduction.nine }}</p>
                <div class="item_cont">
                  <div class="input_box">
                    <input disabled class="" :value="sy_ll" />
                    <div class="extend">BTC/d</div>
                  </div>
                </div>
                <div class="tips">≈$ {{ll_sy}}</div>
              </div>
            </div>

            <div class="_top_line" >
              <div class="line"></div>
              <div class="_center">
                <img src="@/assets/images/_up.png" />
                <p @click="toggleConfig">收起设置</p>
              </div>
              <div class="line"></div>
            </div>

            <div class="_item_center" v-show="configShow">
              <div class="_item">
                <div class="_item_sec">
                  <p class="lebal">{{ langvalue.Bus_introduction.thirteen }}</p>
                  <div class="item_cont">
                    <div class="input_box">
                      <input class="" disabled v-model="bj" />
                      <div class="extend">$</div>
                    </div>
                  </div>
                </div>
                <div class="_item_sec">
                  <p class="lebal">{{ langvalue.Bus_introduction.ele }}</p>
                  <div class="item_cont">
                    <div class="input_box">
                      <input class="" v-model="nd" disabled />
                      <div class="extend">
                        <img src="@/assets/images/chevron_up.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_item_sec">
                  <p class="lebal">{{ langvalue.Bus_introduction.elew }}</p>
                  <div class="item_cont">
                    <div class="input_box">
                      <input class="" v-model="fl" disabled />
                      <div class="extend">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tables">
            <table>
              <thead>
                <tr>
                  <td>{{ langvalue.table_title.one }}</td>
                  <td>{{ langvalue.table_title.tow }}</td>
                  <td>{{ langvalue.table_title.three }}</td>
                  <td>{{ langvalue.table_title.four }}</td>
                  <td>
                    <span>{{langvalue.table_title.five}} $</span>
                    <input v-model="electricity" @change="duChange" />
                    /{{langvalue.table_title.six}}
                  </td>
                  <td>{{langvalue.table_title.seven}}</td>
                  <td>{{langvalue.table_title.eight}}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item, index in goods_list" :key="index">
                  <td>
                    {{item.title}}
                  </td>
                  <td>{{item.calculate}}</td>
                  <td>{{ item.consumption }}</td>
                  <td>$ {{ item.profit_dollar }}</td>
                  <td>$ {{ item.electricity }}</td>
                  <td>
                    <span>$ {{item.price}}</span>
                  </td>
                  <td>{{item.day}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <HomeLine />
  </div>
</template>

<style scoped lang="less">
.a_detail {
  width: 100%;

  .center {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 160px 100px 103px;
    background: #fafbff;

    .title {
      font-size: 40px;
      font-weight: 400;
      color: #000;

      display: flex;
      justify-content: center;
      align-items: center;

      .line {
        display: inline-block;
        width: 100px;
        height: 2px;
        background: #d3d5e0;
      }

      .text {
        margin: 0 10px;
      }
    }

    .c_cont {
      width: 1340px;
      margin: 0 auto;
      padding-top: 30px;

      .tips {
        text-align: center;
        font-size: 16px;
        color: #5d5d84;

        .t_titlt {
          color: #019dff;
        }
      }

      .cont_box {
        // border: 1px solid #000;
        margin-top: 60px;

        .box_top {
          padding-bottom: 30px;

          ._top_line {
            margin-top: 70px;
            display: flex;
            align-items: center;

            .line {
              flex: 1;
              border-bottom: 1px dashed #5d5d84;
            }

            ._center {
              display: flex;
              align-items: center;

              img {
                width: 24px;
                height: 24px;
              }

              p {
                color: #01a0ff;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
              }
            }
          }
        }

        ._item {
          display: flex;

          ._item_sec {
            position: relative;

            .tips {
              position: absolute;
              bottom: -30px;
              left: 30px;
              color: #8484ac;

              font-family: 'PingFang SC';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
            }

            .lebal {
              color: #5d5d84;

              font-family: 'PingFang SC';
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 22.5px;
            }

            flex: 1;

            .item_cont {
              margin-top: 20px;
              display: flex;
            }

            .selec_box {
              padding: 16px 63px 16px 72px;
              border-radius: 2px;
              border: 1px solid #dee0ff;
              background: #fff;

              select {
                border: 0 none;
              }

              margin-right: 10px;
            }

            .input_box {
              flex: 1;
              border: 1px solid #dee0ff;
              border-radius: 2px;
              display: flex;

              input {
                flex: 1;
                width: 200px;
                border: 0 none;

                padding: 2px 20px;
              }

              .extend {
                width: 125px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                border-radius: 2px;
                background: #fafbff;
              }
            }
          }

          .img_box {
            width: 60px;
            height: 60px;
            margin: 40px 30px 0;
            box-sizing: border-box;
          }
        }

        ._item_center {
          ._item_sec+._item_sec {
            margin-left: 30px;
          }
        }
      }

      .tables {
        table {
          width: 100%;
          table-layout: fixed;
          border-radius: 2px;
          border: 1px solid #dee0ff;

          background: #fff;

          thead {
            height: 60px;
            border-radius: 2px;
            background: #f5f9fd;
            border: 0 none;
            border-collapse: collapse;

            td {
              text-align: center;
              color: #8484ac;

              input {
                width: 60px;
              }
            }
          }

          tbody {
            tr {
              td {
                text-align: center;
                color: #34495e;
                height: 80px;

                input {
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}</style>
